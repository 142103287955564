@import "../../../base/bootstrap-extended/include";
@import "../../../base/components/include";

.dark-layout {
    .dx-datagrid {
        font: 20px;
        background-color: #283046;
        color: #d0d2d6;
    }
    .dx-editor-cell .dx-texteditor .dx-texteditor-input {
        background-color: #283046;
        color: #d0d6d1;
    }
    .dx-datagrid-content
        .dx-datagrid-table
        .dx-row
        .dx-editor-cell
        .dx-texteditor,
    .dx-datagrid-content
        .dx-datagrid-table
        .dx-row
        .dx-editor-cell
        .dx-texteditor-container {
        background-color: #283046;
        color: #d0d2d6;
    }
    .dx-toolbar-items-container {
        background-color: #283046;
        color: #d0d2d6;
    }
    .dx-datagrid .dx-column-lines > td {
        border-color: #626e8d;
    }
    .dx-datagrid-borders > .dx-datagrid-headers,
    .dx-datagrid-borders > .dx-datagrid-rowsview,
    .dx-datagrid-borders > .dx-datagrid-total-footer {
        border-color: #626e8d;
    }
    .dx-datagrid-borders > .dx-datagrid-filter-panel {
        border-color: #626e8d;
    }
    .dx-filterbuilder {
        border-color: #626e8d;
    }
    .dx-popup-wrapper > .dx-overlay-content {
        background: #283046 !important;
    }
    .dx-filterbuilder-group {
        background: #283046 !important;
    }

    .dx-filterbuilder .dx-filterbuilder-group .dx-filterbuilder-text {
        color: #d6d2d0;
    }
    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-plus {
        color: #d0d2d6;
    }
    .dx-filterbuilder .dx-filterbuilder-group .dx-icon-remove {
        color: #d0d2d6;
    }
    .dx-treeview-node-container .dx-widget {
        color: #d0d2d6;
    }
    .dx-treeview-item .dx-treeview-item-content span {
        color: #d0d2d6;
    }
    .dx-item-content.dx-list-item-content {
        color: #d0d2d6;
    }
    .dx-icon-export-to {
        color: #d0d2d6;
    }
    .dx-icon-right {
        color: #d0d2d6;
    }
    .dx-checkbox-icon {
        border-color: #d0d2d6;
    }
    .dx-treeview ul {
        padding-left: 0px;
    }
    .dx-popup-wrapper > .dx-overlay-content {
        border-color: #626e8d;
    }
    .dx-popup-title {
        border-color: #626e8d;
    }
    .dx-toolbar-after .dx-button {
        border-color: #626e8d;
        background: #283046 !important;
        color: #d0d2d6;
    }

    .dx-button-mode-text .dx-icon {
        color: #d0d2d6;
    }
    .dx-button-content {
        color: #d0d2d6;
    }
    .dx-texteditor-input {
        background: #283046 !important;
        color: #d0d6d1;
    }
    .dark-layout .dx-toolbar-after .dx-button {
        background: #283046 !important;
        color: #d0d2d6;
    }

    // selected row in dev grid table csss

    .dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
        border: 1px solid #114d2b;
        // background: #d0d2d6 !important;
        color: #d0d2d6;
    }
    .dx-popup-content
        .dx-datagrid-checkbox-size.dx-checkbox-checked
        .dx-checkbox-icon {
        border: 1px solid #114d2b;
        background: #084eda !important;
        color: #302a27;
    }
    // .dx-checkbox-icon {
    //     background: #d0d2d6 !important;
    // }
    // .dx-context-menu-container-border {
    //     background: #d0d2d6 !important;
    //     color: black;
    // }
    .dx-datagrid-filter-row {
        background: #283046;
    }
    .dx-datagrid-header-panel {
        background: #283046;
    }
    .dx-dropdowneditor-icon {
        background: #283046;
    }
    .dx-rtl
        .dx-datagrid-rowsview
        .dx-selection.dx-row
        > td:not(.dx-focused).dx-datagrid-group-space,
    .dx-rtl
        .dx-datagrid-rowsview
        .dx-selection.dx-row:hover
        > td:not(.dx-focused).dx-datagrid-group-space {
        border-left-color: #d7d4f3;
    }

    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines) > td,
    .dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines) > td {
        border-bottom: 1px solid #d7d4f3;
        border-top: 1px solid #d7d4f3;
    }

    .dx-datagrid-rowsview .dx-selection.dx-row > td.dx-datagrid-group-space,
    .dx-datagrid-rowsview
        .dx-selection.dx-row:hover
        > td.dx-datagrid-group-space {
        border-right-color: #d7d4f3;
    }

    .dx-datagrid-rowsview .dx-selection.dx-row > td,
    .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
        background-color: rgb(133, 142, 163);
        color: #080808;
    }

    .dx-datagrid-table
        .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
        > td:not(.dx-focused) {
        background-color: #443c89;
        color: #333;
    }
    // .dx-state-hover {
    //     // background-color: #443c89;
    //     // background-color: red;
    //     color: white;
    // }
    .dx-context-menu .dx-submenu {
        // background-color: #655ccf;
        background-color: #d0d2d6;

        color: rgb(41, 37, 37);
    }
    // .dx-popup-content{}
    .dx-calendar {
        background-color: #d0d2d6;
    }
    .dx-button-mode-contained {
        background-color: #d0d2d6;
    }
    .dx-widget {
        color: #d0d2d6;
    }
    .dx-treeview-item-with-checkbox .dx-treeview-item {
        color: #d0d2d6;
    }
    .dx-filter-menu.dx-menu .dx-menu-item .dx-menu-item-content {
        padding: 10px 5px 7px;
    }

    .dx-pager .dx-page-sizes .dx-selection,
    .dx-pager .dx-pages .dx-selection {
        background-color: #114d2b;
    }
    .dx-datagrid-filter-panel .dx-icon-filter {
        color: white;
    }
    .dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
    .dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
        color: white;
    }
    .dx-datagrid-headers {
        color: #d0d2d6;
    }

    .dx-datagrid .dx-header-filter::before {
        color: white;
    }

    .dx-group-panel-message {
        color: white;
    }

    .dx-datagrid-container.dx-filter-menu .dx-menu-item-has-icon .dx-icon,
    .dx-datagrid.dx-filter-menu .dx-menu-item-has-icon .dx-icon {
        color: white;
    }
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort,
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-sort-indicator,
    .dx-datagrid-headers
        .dx-datagrid-table
        .dx-row
        > td:hover
        .dx-datagrid-text-content {
        color: #d0d2d6;
    }

    .dx-datagrid-rowsview .dx-row.dx-group-row td {
        background-color: #283046;
        color: #d0d2d6;
    }
    .dx-datagrid .dx-header-filter::before {
        // color: white;
        color: #757575;
    }
    .dx-datagrid-group-opened {
        color: #d4d4d4;
    }
    .dx-datagrid-group-closed {
        color: #d4d4d4;
    }

    .dx-datagrid .dx-row-lines > td {
        border-bottom: 1px solid #626e8d;
    }
    .dx-list-item .dx-icon {
        color: #d4d4d4;
    }
    .dx-scrollable-simulated .dx-scrollable-content {
        border: 1px solid #626e8d;
    }
}

//  regular light theme

.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
    border: 1px solid #114d2b;
    background-color: #114d2b;
    color: white;
}
.dx-checkbox-checked .dx-checkbox-icon {
    border: 1px solid #114d2b;
    background-color: #114d2b;
    color: white;
}

.dx-rtl
    .dx-datagrid-rowsview
    .dx-selection.dx-row
    > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl
    .dx-datagrid-rowsview
    .dx-selection.dx-row:hover
    > td:not(.dx-focused).dx-datagrid-group-space {
    border-left-color: #d7d4f3;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines) > td {
    border-bottom: 1px solid #d7d4f3;
    border-top: 1px solid #d7d4f3;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td.dx-datagrid-group-space {
    border-right-color: #d7d4f3;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
    background-color: rgb(224, 241, 232);

    color: #232323;
}

.dx-datagrid-table
    .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
    > td:not(.dx-focused) {
    background-color: #d7d4f3;
    color: #333;
}

.dx-datagrid {
    font: 20px;
    // background-color: #283046;
    // color: #fff;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
    background-color: #114d2b;
}
.dx-datagrid-filter-panel .dx-icon-filter {
    color: #114d2b;
}
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter,
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text {
    color: #114d2b;
}
.dx-checkbox-icon {
    margin-top: 0.0825rem;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #d8d6de;
}
.dx-button-mode-text.dx-button-default {
    color: #114d2b;
}
[dir="ltr"] ol,
[dir="ltr"] ul {
    padding-left: 0;
}

// .dx-row .dx-header-row .dx-datagrid-drop-highlight {
//     background-color: red !important;
// }
