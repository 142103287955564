@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

body {
    padding-right: 0 !important;
}

.form-label {
    font-size: 1rem;
    color: #4b4b4b;
}

h5 {
    font-weight: bold;
}

.rdt_TableBody {
    .rdt_TableRow {
        color: #4b4b4b !important;
    }
}

.modal .modal-header {
    background-color: '#7367f0 ' !important;
}

// .gelpCx {
//     overflow-x: clip !important;
//     overflow-y: visible !important;
// }

.form-control:disabled {
    color: #000 !important;
}

.form-control:read-only {
    color: #000 !important;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    padding: 0px 16px 0px 16px !important;
}

.accordion-margin {
    .accordion-item {
        box-shadow: 0 2px 15px 0 rgb(34, 41, 47, 10%) !important;
    }
}

.swiper-slide.swiper-slide-active {
    text-align: center;
}
.swiper-slide.swiper-slide-active img {
    // max-height: 200px !important;
}
.slider-main {
    max-height: 450px;
    .swiper-slide.swiper-slide-active img {
        max-height: 450px !important;
    }
}

.brand-logo img {
    max-height: 70px;
}
